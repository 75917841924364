import { createSlice } from '@reduxjs/toolkit'

const sucideUserInitialState = {
  unAssignedUserList: [],
  assignedUserList: [],
  endChatUser: [],
  unAssignedUserCount: 0,
  assignedUserCount: 0,
  endChatState: false,
  removeUserFromHistory: false,
  available: false,
}

export const sucideUserReducer = createSlice({
  name: 'sucideUser',
  initialState: sucideUserInitialState,
  reducers: {
    setEndChatState: (state, action) => {
      state.endChatState = action.payload
    },
    setEndChatUserName: (state, action) => {
      const list = [...state.endChatUser]
      const index = list.findIndex((e) => action.payload._id === e._id)
      list[index].name = action.payload.name
      state.endChatUser = [...list]
    },
    setRemoveUserFromHistory: (state, action) => {
      state.removeUserFromHistory = action.payload
    },
    setUnAssignedUserList: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.unAssignedUserList = [...action.payload]
      } else {
        const list = [...state.unAssignedUserList]
        if (action.payload.role === 'user') {
          const index = list.findIndex((e) => action.payload._id === e._id)
          if (index !== -1) {
            if (action.payload.assigned) {
              list.splice(index, 1)
            } else {
              list[index].online = action.payload.online
            }
          } else if (!action.payload.assigned) {
            list.push(action.payload)
          }
        }
        state.unAssignedUserList = [...list]
      }
    },
    updateUnassignedUser: (state, action) => {
      const list = [...state.unAssignedUserList]
      const index = list.findIndex((e, i) => e._id === action.payload._id)
      if (index !== -1) {
        list[index].online = action.payload.online
        state.unAssignedUserList = [...list]
      }
    },
    removeUnAssignedUser: (state, action) => {
      const list = [...state.unAssignedUserList]
      const index = list.findIndex((e) => e._id === action.payload._id)
      list.splice(index, 1)
      state.unAssignedUserList = [...list]
    },
    setAssignedUserList: (state, action) => {
      if (action.payload.endChat) return
      if (Array.isArray(action.payload)) {
        state.assignedUserList = [...action.payload]
      } else {
        const list = [...state.assignedUserList]
        if (action.payload.userId) {
          const exist = state.assignedUserList.some((e) => e._id === action.payload._id)
          if (action.payload.assignedBy === action.payload.userId && !exist) {
            delete action.payload.userId
            list.unshift(action.payload)
          }
        } else {
          const index = list.findIndex((e) => action.payload._id === e._id)
          if (index === -1) {
            list.unshift(action.payload)
          } else if (action.payload.assigned) {
            list[index].online = action.payload.online
            if (!action.payload.online) {
              list.push(list[index])
              list.splice(index, 1)
            }
          }
        }
        state.assignedUserList = [...list]
      }
    },
    setUserCount: (state, action) => {
      state.unAssignedUserCount = action.payload
    },
    setChatHistory: (state, action) => {
      const list = [...state.assignedUserList]
      let newList = []
      list.forEach((e) => {
        if (action.payload._id === e._id) {
          state.endChatUser.push(e)
        } else {
          newList.push(e)
        }
      })
      state.assignedUserList = [...newList]
    },

    setAddUser: (state, action) => {
      const list = [...state.endChatUser]
      list.splice(0, 0, action.payload)
      state.endChatUser = [...list]
    },

    setHistory: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.endChatUser = [...action.payload]
      } else {
        const list = [...state.endChatUser]
        const exist = state.endChatUser.some((e) => e._id === action.payload._id)

        if (!exist) {
          list.push(action.payload)
        }

        state.endChatUser = [...list]
      }
    },
    setAssignedUserName: (state, action) => {
      const list = [...state.assignedUserList]
      const index = list.findIndex((e) => action.payload._id === e._id)
      list[index].name = action.payload.name
      state.assignedUserList = [...list]
    },
    setUserAdd: (state, action) => {
      let list = [...state.endChatUser]
      const index = list.findIndex((e) => action.payload._id === e._id)
      list.splice(index, 1)
      state.endChatUser = [...list]
    },
    clearEndUser: (state, action) => {
      state.unAssignedUserList = []
      state.assignedUserList = []
      state.endChatUser = []
      state.unAssignedUserCount = 0
      state.assignedUserCount = 0
      state.endChatState = false
    },
    removeAssignedUser: (state, action) => {
      const list = [...state.assignedUserList]
      const index = list.findIndex((e) => e._id === action.payload)
      if (index !== -1) {
        list.splice(index, 1)
      }
      state.assignedUserList = [...list]
    },
    setAvailable: (state, action) => {
      state.available = action.payload
    },
    clearSucideUserStore: (state, action) => {
      return {
        unAssignedUserList: [],
        assignedUserList: [],
        endChatUser: [],
        unAssignedUserCount: 0,
        assignedUserCount: 0,
        endChatState: false,
        removeUserFromHistory: false,
        available: false,
      }
    },
  },
})

export const {
  setAssignedUserList,
  setUserCount,
  setUnAssignedUserList,
  setChatHistory,
  setAddUser,
  setEndChatState,
  setHistory,
  setUserAdd,
  clearEndUser,
  setAssignedUserName,
  removeAssignedUser,
  setRemoveUserFromHistory,
  setEndChatUserName,
  setAvailable,
  removeUnAssignedUser,
  clearSucideUserStore,
  updateUnassignedUser,
} = sucideUserReducer.actions

export default sucideUserReducer.reducer
