import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import ProtectedRoute from './views/Routes/ProtectedRoutes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import Terms from './views/info/TermsCondition'
import Faqs from './views/info/Faqs'
import PrivacyPolicy from './views/info/PrivacyPolicy'
import Disclaimer from './views/info/Disclaimer'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Login/Login'))
const ForgetPassword = React.lazy(() => import('./views/Login/forgetPassword'))
const User = React.lazy(() => import('./views/User/User'))

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route index path="/" name="Login Page" element={<Login />} />
          <Route
            index
            path="/forget-password"
            name="Forgot Password Page"
            element={<ForgetPassword />}
          />
          <Route index path="/terms" name="Terms" element={<Terms />} />
          <Route index path="/faqs" name="Terms" element={<Faqs />} />
          <Route index path="/privacy" name="Terms" element={<PrivacyPolicy />} />
          <Route index path="/disclaimer" name="Terms" element={<Disclaimer />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/*" name="Home" element={<DefaultLayout />} />
          </Route>
          <Route path="user" name="User" element={<User />} />
        </Routes>
      </Suspense>
      <ToastContainer position="bottom-center" hideProgressBar />
    </BrowserRouter>
  )
}

export default App
