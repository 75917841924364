import { createSlice } from '@reduxjs/toolkit'

const commonInitialState = {
  token: null,
  user: null,
  user_details: {},
  showFeedback: false,
  adminId: 'anonomyous',
}

export const commonSlice = createSlice({
  name: 'common',
  initialState: commonInitialState,
  reducers: {
    setUser: (state, action) => {
      state = { ...state, ...action.payload }
      return state
    },
    setProfile: (state, action) => {
      state = { ...state, ...action.payload }
      return state
    },
    setUserDetails: (state, action) => {
      state = { ...state, user_details: action.payload }
      return state
    },
    setShowFeedback: (state, action) => {
      state.showFeedback = action.payload
    },
    setAdminId: (state, action) => {
      state.adminId = action.payload
    },
    logout: (state, action) => {
      return {
        token: null,
        user: null,
        user_details: {},
        showFeedback: false,
        setAdminId: 'anonomyous',
      }
    },
  },
})

export const { setUser, setProfile, setUserDetails, setShowFeedback, setAdminId, logout } =
  commonSlice.actions

export default commonSlice.reducer
