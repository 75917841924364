import React from 'react'

const TermsAndConditions = () => {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>
        Terms and Conditions
      </h1>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          <h3>Nature of Service/Scope</h3>
          <p>
            iCALL’s is a chat-based crisis support service aimed at providing psychosocial support
            to youth (individuals between 18-24 years of age) facing mental health challenges.
          </p>
        </li>
        <li>
          <h3>Consent</h3>
          <p>
            By accessing or using the service, you agree to the terms of service and privacy policy
            described herein.
          </p>
        </li>
        <li>
          <h3>Collection of Personal Information</h3>
          <p>
            During usage, you may be asked to provide personally identifiable information such as
            phone number, age, gender, and location.
          </p>
        </li>
        <li>
          <h3>Use of Personal Information</h3>
          <p>
            Personal information may be used to understand clients' needs, provide better service,
            and perform activities necessary to carry out iCALL’s chat service.
          </p>
        </li>
        <li>
          <h3>Storage of Data</h3>
          <p>
            Data collected during conversations will be retained indefinitely, with personal details
            anonymized for research purposes.
          </p>
        </li>
        <li>
          <h3>How We Protect Your Personal Information</h3>
          <p>
            Various security measures are implemented to protect your data from unauthorized access,
            disclosure, alteration, and destruction.
          </p>
        </li>
        <li>
          <h3>Data Deletion</h3>
          <p>
            Users have the right to request the deletion of all their data and account by contacting
            our support team at <strong>9152987821</strong>. Upon receiving the request, we will
            proceed to delete all personal data and associated account information from our system.
          </p>
        </li>

        <li>
          <h3>Confidentiality</h3>
          <p>
            iCALL respects the confidentiality of user data and ensures that no identifying
            information is disclosed to external parties except in exceptional circumstances.
          </p>
        </li>
        <li>
          <h3>Disclosure to Third Parties</h3>
          <p>Personal information is not disclosed to other parties.</p>
        </li>
        <li>
          <h3>Prohibited Uses</h3>
          <p>
            Usage of services for unlawful or inappropriate purposes, including harassment or abuse,
            is strictly prohibited.
          </p>
        </li>
      </ul>
    </div>
  )
}

export default TermsAndConditions
