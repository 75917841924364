import { createContext, useState } from 'react'

const ChatContext = createContext()

const ChatProvider = ({ children }) => {
  const [socket, setSocket] = useState(null)

  return (
    <ChatContext.Provider value={{ socket: socket, setSocket: setSocket }}>
      {children}
    </ChatContext.Provider>
  )
}

export { ChatContext, ChatProvider }
