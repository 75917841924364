import { createSlice } from '@reduxjs/toolkit'

const layoutInitialState = {
  showSidebar: true,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: layoutInitialState,
  reducers: {
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload
    },
  },
})

export const { setShowSidebar } = layoutSlice.actions

export default layoutSlice.reducer
