import React from 'react'

const Disclaimer = () => {
    return (
        <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold', margin: '40px' }}>
                Disclaimer: ReYou, iCALL’s Mental Health Application
            </h1>
            <ul style={{ paddingLeft: '20px' }}>
                <div className='d-flex'>
                    <span className="fs-3">→</span>
                    <span className='mt-2 p-1'>
                        {/* <p> */}
                        This Application is suitable for use by young adults who want to access mental health care resources.
                        {/* </p> */}
                    </span>
                </div>
                <div className='d-flex'>
                    <span className="fs-3">→</span>
                    <span className='mt-2 p-1'>
                        <p>
                            The content on this Application is meant solely for information purposes.
                        </p>
                    </span>
                </div>
                <div className='d-flex'>
                    <span className="fs-3">→</span>
                    <span className='mt-2 p-1'>
                    <p>
                        The Application is designed to offer information on various facets of mental health for young adults. It also provides evidence-based tools and techniques to help manage emotions and promote mental well-being in a self-help setting.
                    </p>
                    </span>
                </div>
                <div className='d-flex'>
                    <span className="fs-3">→</span>
                    <span className='mt-2 p-1'>
                    <p>
                        The content on the application does not constitute care, treatment or other professional advice and is not meant to substitute any advice provided by mental/medical professionals.
                    </p>
                    </span>
                </div>
                <div className='d-flex'>
                    <span className="fs-3">→</span>
                    <span className='mt-2 p-1'>
                    <p>
                        The services on this Application are not intended to diagnose mental health illnesses or to take the place of face-to-face professional care.
                    </p>
                    </span>
                </div>
                <div className='d-flex'>
                    <span className="fs-3">→</span>
                    <span className='mt-2 p-1'>
                    <p>
                        The Application is not intended for use in crisis, emergencies or severe mental health conditions.
                        If you feel you are experiencing a mental health emergency, such as considering or contemplating 
                        suicide, we would urge you to connect with us via iCALL’s <a href='https://icallhelpline.org/' target='_blank'>ReYou</a>, our confidential crisis chat-line
                        by clicking on the message icon, available Monday to Friday 9:00 am to 9:00 pm. Additionally, 
                        you can reach out to iCALL’s helpline at 9152987821 and email counselling assistance at <u>icall@tiss.edu</u>,
                        available from Monday to Saturday 10:00 am to 8:00 pm.  You can also seek help at the nearest hospital
                        or connect with a psychiatrist or a psychologist in person. The same applies in case of any medical
                        or physical health emergency. We also recommend involving a close family member or a friend who can
                        offer immediate support.
                    </p>
                    </span>
                </div>
            </ul>
        </div>
    )
}

export default Disclaimer
