import { createSlice } from '@reduxjs/toolkit'

const messageInitialState = {
  messageList: [],
  assignedUser: {
    assignedUser: null,
    // chatId: null,
    online: false,
    phoneNumber: '',
    name: '',
    age: '',
    gender: '',
    country: '',
  },
  unReadMessageCount: {},
  offlineMessageList: [],
  loading: false,
  notify: false,
  unReadMessageCountTotal: 0,
}

export const messageSlice = createSlice({
  name: 'message',
  initialState: messageInitialState,
  reducers: {
    setMessageList: (state, action) => {
      const request = action.payload.req
      const adminId = action.payload.adminId
      if (Array.isArray(request)) {
        request.sort(function compare(a, b) {
          var dateA = new Date(a.createdAt)
          var dateB = new Date(b.createdAt)
          return dateA - dateB
        })
        state.messageList = [...request]
      } else {
        if (
          request.from === state.assignedUser.assignedUser ||
          (adminId === request.from && request.to === state.assignedUser.assignedUser) ||
          ('anonomyous' === request.from && request.to === state.assignedUser.assignedUser)
        ) {
          const list = [...state.messageList]
          const index = list.findIndex((e) =>  new Date(e.createdAt).toISOString() === new Date(request.createdAt).toISOString())
          if (index != -1) {
            list.splice(index, 1)
          }

          list.push(request)
          state.messageList = [...list]
        }
      }

      state.loading = false
    },
    setAssignedUser: (state, action) => {
      if (action.payload.updateUser) {
        if (
          state.assignedUser.assignedUser &&
          state.assignedUser.assignedUser === action.payload.assignedUser &&
          state.assignedUser.online !== action.payload.online
        ) {
          delete action.payload.updateUser
          state.assignedUser = action.payload
        }
      } else {
        state.assignedUser = action.payload
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setNotify: (state, action) => {
      state.notify = action.payload
    },
    setUnReadMessageCount: (state, action) => {
      const userId = action.payload.userId
      const check = action.payload.assignedUserList.map((element) => {
        if (element._id === userId) return true
        else return false
      })
      if (action.payload.reset) {
        state.unReadMessageCountTotal =
          state.unReadMessageCountTotal -
          (state.unReadMessageCount[userId] > 0 ? state.unReadMessageCount[userId] : 0)
        state.unReadMessageCount[userId] = 0
      } else if (userId && state.assignedUser.assignedUser !== userId) {
        if (state.unReadMessageCount[userId]) {
          state.unReadMessageCount[userId] += 1
          state.unReadMessageCountTotal += 1
        } else if (check.includes(true)) {
          state.unReadMessageCount[userId] = 1
          state.unReadMessageCountTotal += 1
        }
        if (check.includes(true)) {
          state.notify = true
        }
      }
    },
    setAssignedUserNewName: (state, action) => {
      const list = { ...state.assignedUser }
      list.name = action.payload.name
      state.assignedUser = { ...list }
    },
    getAllMessage: (state, action) => {
      action.payload.object.assignedUser = state.assignedUser.assignedUser
      action.payload.object.messages = state.offlineMessageList
    },
    setOfflineMessage: (state, action) => {
      const list = [...state.offlineMessageList]
      list.push(action.payload)
      state.offlineMessageList = [...list]
    },
    removeOfflineMessage: (state, action) => {
      state.offlineMessageList = []
    },
    removeAssignUser: (state, action) => {
      if (!action.payload) {
        state.assignedUser = {
          assignedUser: null,
          // chatId: null,
          online: false,
          phoneNumber: '',
          name: '',
        }
      } else {
        if (action.payload && state.assignedUser.assignedUser === action.payload.userId) {
          state.assignedUser = {
            assignedUser: null,
            // chatId: null,
            online: false,
            phoneNumber: '',
            name: '',
          }
        }
      }
    },
    clearMessageStore: (state, action) => {
      return {
        messageList: [],
        assignedUser: {
          assignedUser: null,
          // chatId: null,
          online: false,
          phoneNumber: '',
          name: '',
          age: '',
          gender: '',
          country: '',
        },
        unReadMessageCount: {},
        unReadMessageCountTotal: 0,
        offlineMessageList: [],
        loading: false,
        notify: false,
      }
    },
  },
})

export const {
  removeAssignUser,
  setMessageList,
  setAssignedUser,
  setUnReadMessageCount,
  setAssignedUserNewName,
  getAllMessage,
  setLoading,
  setNotify,
  setOfflineMessage,
  removeOfflineMessage,
  clearMessageStore,
} = messageSlice.actions

export default messageSlice.reducer
