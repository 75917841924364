import { combineReducers } from 'redux';
import commonReducer from './commomReducer.js/commonReducer';
import layoutReducer from './layoutReducer/layoutReducer';
import messageReducer from './messageReducer.js/messageReducer';
import sucideUserReducer from './sucideUserReducer.js/sucideUserReducer';

export const rootReducer = combineReducers({
  common: commonReducer,
  sucideUser: sucideUserReducer,
  message: messageReducer,
  layout: layoutReducer,
});
