import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer } from './redux/reducer/rootReducer'

const persistConfig = {
  key: 'IChat',
  storage,
  whitelist: ['common']
}

const reducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
const persistor = persistStore(store)

export { store, persistor };
