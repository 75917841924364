import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = () => {
  const { token, user } = useSelector((state) => state.common)

  const isAuth = () => {
    if (token && user && window.location.pathname.includes('/counselor')) {
      if (user.role === 'superAdmin') {
        return true
      } else {
        return false
      }
    }
    if (token && user && window.location.pathname.includes('/dashboard')) {
      if (user.role === 'superAdmin') {
        return true
      } else {
        return false
      }
    }
    if (token && user && window.location.pathname.includes('/activechats')) {
      if (user.role === 'consultant') {
        return true
      } else {
        return false
      }
    }
    if (token) {
      return true
    } else {
      return false
    }
  }

  return isAuth() ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoute
