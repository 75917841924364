import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
            <h1 style={{ fontSize: '22px', fontWeight: 'bold', margin: '30px' }}>
                Privacy Policy: ReYou, iCALL’s Mental Health Application

            </h1>
            <ul style={{ listStyleType: 'number', paddingLeft: '20px' }}>
                <li>
                    <h6>General Disclaimer </h6>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>
                            This application is suitable for use by young adults.
                        </li>
                        <li>
                            The Application is available on the Google Play Store and is free to download and use.

                        </li>
                        <li>
                            The content on this Application is meant solely for information purposes and does not constitute care, treatment, or other professional advice and is not meant to substitute any advice provided by medical professionals or therapists.

                        </li>
                        <li>
                            The services on this application are not intended to diagnose mental health problems or to take the place of treatment by a mental health professional.
                        </li>
                    </ul>
                </li>
                <li className='mt-2'>
                    <h6>Nature of Service</h6>
                    <p>
                        ReYou, iCALL’s Mental Health Application, is a mental well-being app offering information
                        about youth mental health topics, along with evidence-based self-help tools and techniques
                        for managing emotions and promoting overall well-being. Using the Application, you will learn
                        self-help techniques that can help you feel better.
                    </p>
                    <p>
                        The Application is not intended to be a substitute for professional mental health or
                        medical care. If you are experiencing a mental health crisis, such as thoughts of
                        suicide or self-harm, please contact a mental health professional or crisis helpline
                        immediately. In case of a physical health emergency, seek medical attention promptly.
                        We encourage you to reach out to a trusted friend, family member, or healthcare provider
                        for support. Additionally, you may contact iCALL’s <a href='https://icallhelpline.org/' target='_blank'>ReYou</a>, our confidential crisis
                        chat-line by clicking on the message icon, available Monday to Friday 9:00 am to 9:00 pm
                        or reach out to iCALL’s helpline at 9152987821 and email counselling assistance at <u>icall@tiss.edu</u>, available from Monday to Saturday 10:00 am to 8:00 pm.
                    </p>
                </li>
                <li>
                    <h6>Collection and Use of Personal Information</h6>
                    <p>
                        When you use ReYou, iCALL’s Mental Health Application, no personal information is collected from you. We may only collect information about your activity on the app, such as when you first start using the Application. We may use this automatically-gathered information to enhance the quality of Services and this information is never shared with any third parties or anybody outside our organization.
                    </p>
                </li>
                <li>
                    <h6>Confidentiality </h6>
                    <p>
                        Since we do not collect any personal information, there is no risk of sharing it with third parties. We will use industry-standard security measures to protect any anonymized data we collect.

                    </p>
                </li>
                <li>
                    <h6>Grievance/Complaint </h6>
                    <p>
                        If you wish to contact us regarding a technical issue or have concerns about the app's content, you can lodge a written request at the following email address:
                    </p>
                    <p>
                        Email Address: <u>icall.zoomcares@gmail.com</u>
                    </p>
                </li>
            </ul>
        </div>
    )
}

export default PrivacyPolicy
