import React from 'react'

const Faqs = () => {
    return (
        <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
            <h1 style={{ fontSize: '22px', fontWeight: 'bold', margin: '30px' }}>
                Frequently Asked Questions: ReYou, iCALL’s Mental Health Application
            </h1>
            <ul style={{ listStyleType: 'Number', paddingLeft: '20px' }}>
                <div className='mb-3'>
                    <h5>About the App</h5>
                </div>
                <li>
                    <h6>What is the purpose of this app?</h6>
                    <p>
                        The app can be used by young adults who want to manage common concerns like everyday stress, anxiety, and worry. It provides information about various mental health concerns and self-help strategies to improve overall well-being and manage distress.
                    </p>
                </li>
                <li>
                    <h6>What is the aim of developing the mental health app?
                    </h6>
                    <p>
                        The app has been developed with the aim of presenting an evidence-based, easily accessible digital platform that helps young adults manage their mental health. The app can be helpful for young people who are looking for self-help resources, and who wish to learn about and manage their mental health concerns.

                    </p>
                </li>
                <li>
                    <h6>Is the app free to use?
                    </h6>
                    <p>
                        The app is completely free to use.

                    </p>
                </li>
                <li>
                    <h6>Who should use this app?
                    </h6>
                    <p>
                        This app is intended for young adults who want to improve their mental well-being through self-help resources. Please note that the app is not a substitute for professional mental health care.

                    </p>
                </li>
                <li>
                    <h6>How do I access the app?
                    </h6>
                    <p>
                        The app is available for download through the Google Play Store.

                    </p>
                </li>
                <li>
                    <h6>
                        Do I need to sign up to use the app?
                    </h6>
                    <p>
                        No, you don't need to sign up or log in to use the app.
                    </p>
                </li>
                <li>
                    <h6>Can the app be used to diagnose mental health problems?
                    </h6>
                    <p>
                        The app and its contents are designed for information purposes only and cannot be used to diagnose or treat mental health problems. The app is not a substitute for professional mental health care or emergency services.

                    </p>
                </li>

                <li>
                    <h6>What should I do if I'm feeling overwhelmed while using the app?
                    </h6>
                    <p>
                        If you are feeling overwhelmed or distressed and think you may need more help,
                        we would urge you to connect with us via iCALL’s <a href='https://icallhelpline.org/' target='_blank'>ReYou</a>, our confidential crisis chat-line
                        by clicking on the message icon, available Monday to Friday 9:00 am to 9:00 pm. Additionally,
                        you can reach out to iCALL’s helpline at 9152987821 and email counselling assistance at <u>icall@tiss.edu</u>,
                        available from Monday to Saturday 10:00 am to 8:00 pm. Alternatively, you can visit the SOS section on
                        the app that provides the numbers of the 24*7 helplines that deal with crisis issues.

                    </p>
                </li>
                <li>
                    <h6>What if I'm not experiencing any difficulty right now? Can I still use the app?
                    </h6>
                    <p>Yes, of course! The app offers valuable resources even if you're not currently experiencing mental health challenges. You can access articles on various topics like understanding mental health, supporting loved ones, practicing self-compassion, and more.
                    </p>
                </li>
                <div className='mb-3 ml-4'>
                    <h5>App Features</h5>
                </div>
                <li>
                    <h6>What are the different things I can do on the app?
                    </h6>
                    <div>
                        The app offers a number of articles that talk about various forms of emotional distress, simple reflective exercises to help you gain more information about your situation, and self-help resources to support your mental well-being, including:
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', gap: '5px' }}>
                            <li className='mt-2'>  Information and tools: Learn about common psychological and emotional issues like stress and anxiety, as well as crisis situations, like suicidal ideation and self-harm. Access articles with strategies to reflect upon these challenging scenarios and help you manage them.</li>
                            <li className='mt-2 mb-2'>  Crisis support: If you're feeling overwhelmed, the SOS feature provides simple
                                yet effective tools for immediate support. Create a safety plan, practice grounding techniques,
                                and access additional resources. Remember, if you feel extremely vulnerable, reach out to iCALL’s <a href='https://icallhelpline.org/' target='_blank'>ReYou</a>,
                                our confidential crisis chat-line by clicking on the message icon, available Monday to Friday 9:00 am to 9:00 pm.
                                Additionally, you can reach out to iCALL’s helpline at 9152987821 and email counselling assistance
                                at <u>icall@tiss.edu</u>, available from Monday to Saturday 10:00 am to 8:00 pm. The SOS feature also provides a
                                list of 24*7 helplines.
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <h6>
                        What are the self-help resources?
                    </h6>
                    <p>
                        The self-help resources provide information and tools you can use at your own pace to improve your mental well-being. These resources include:
                    </p>
                    <div>
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', gap: '5px' }}>
                            <li className='mt-2'> Articles: Read informative articles about various mental health topics relevant to young adults.</li>
                            <li className='mt-2 mb-2'> Management tools: Find practical exercises and strategies for managing stress, anxiety, procrastination, and other common concerns.
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <h6>What is SOS about?

                    </h6>
                    <p>If you believe that you require emergency support to reduce your distress, 
                        you can use the SOS section to access self-help resources. We strongly advise 
                        connecting at iCALL’s <a href='https://icallhelpline.org/' target='_blank'>ReYou</a>, our confidential crisis chat-line by clicking on
                        the message icon, available Monday to Friday 9:00 am to 9:00 pm. Additionally, 
                        you can reach out to iCALL’s helpline at 9152987821 and email counselling assistance
                        at <u>icall@tiss.edu</u>, available from Monday to Saturday 10:00 am to 8:00 pm if you believe
                        your level of distress is significant and these resources would not be helpful.

                    </p>
                </li>
                <li>
                    <h6>Who has developed the resources on the app?
                    </h6>
                    <p>Our self-help resources are created by qualified mental health professionals, specifically addressing the psychosocial issues young people face today. The focus is on providing evidence-based information and practical tools to empower you on your mental wellness journey.

                    </p>
                </li>
                <div className='mb-3'>
                    <h5>Privacy
                    </h5>
                </div>
                <li>
                    <h6>What personal information is collected?
                    </h6>
                    <p>We do not ask for and collect any personal information of the user. This means we don't collect details like your name, email address, location, or any other information that can identify you.
                        We may collect anonymized data about your activity within the app, such as when you first started using it. This data is used solely to improve the quality of the services offered and is never shared with any third parties or anybody outside our organization.

                    </p>
                </li>
                <li>
                    <h6>How do you ensure the security of the content on the app?
                    </h6>
                    <p>We maintain industry standards for security of the content on the app as well as app security.

                    </p>
                </li>
                <div className='mb-3'>
                    <h5>  Getting Further Help
                    </h5>
                </div>
                <li>
                    <h6>How do I report a problem with the app?
                    </h6>
                    <p>
                        If you encounter a technical issue or have concerns about the app's content, please email us at <u>icall.zoomcares@gmail.com</u> For technical issues, please include details about your device (e.g., model, operating system version) to help us diagnose and resolve the problem faster.
                    </p>
                </li>
                <li>
                    <h6>Where can I get further help?
                    </h6>
                    <p>ReYou, iCALL’s mental health app, uses an evidence-based approach to provide you 
                        with information and self-help tools but it does not substitute for the assessment 
                        and treatment of a mental health professional. Therefore, we encourage you to reach 
                        out to other services if you’re feeling overwhelmed. You can connect with us via <a href='https://icallhelpline.org/' target='_blank'>ReYou</a>, 
                        iCALL’s crisis chat-line or contact iCALL’s helpline at 9152987821. If you are contemplating 
                        self-harm with suicidal intent please talk to a responsible adult and see a health 
                        professional urgently. Do not hesitate to seek help at the nearest hospital or 
                        emergency room where you can connect with a psychiatrist or a psychologist in person.
                         The same applies in case of any medical or physical health emergency.

                    </p>
                </li>
            </ul>
        </div>
    )
}

export default Faqs
